



































































import { Component, Vue } from 'vue-property-decorator'
import { ProfileService } from '@/services/ProfileService'
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue'

@Component({ 
  components:{ 
    SvgIcon
  }
})
export default class ProfileDropdown extends Vue {
  get profile () {
    return ProfileService.profile
  }

  get fullName() {
    return ProfileService.fullName
  }
}
