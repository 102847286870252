import { ProfileService } from '@/services/ProfileService'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/reports',
    component: () => import(/* webpackChunkName: "Reports" */ '../views/Reports/ReportsTemplate.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "reports-home" */ '../views/Reports/ReportHome.vue'),
      },
      {
        path: ':reportId',
        name: 'Report',
        component: () => import(/* webpackChunkName: "report" */ '../views/Reports/Report.vue'),
      }
    ]

  },
    {
    path: '/earnings-dashboard',
    component: () => import(/* webpackChunkName: "Reports" */ '../views/Reports/ReportsTemplate.vue'),
    meta: { requiresFeatureToggle: 'hasEarningsReport' },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "reports-home" */ '../views/Reports/ReportHome.vue'),
      },
      {
        path: ':reportId',
        name: 'Earnings Dashboard',
        component: () => import(/* webpackChunkName: "report" */ '../views/Reports/Report.vue'),
      }
    ]
  },
  {
    path: '/agreements',
    name: 'Agreements',
    component: () => import(/* webpackChunkName: "agreements" */ '../views/Agreements/Agreements.vue'),
    meta: { requiresFeatureToggle: 'featureToggleRoyalty' }
  },
  {
    path: '/payment',
    name: 'Payment Settings',
    component: () => import(/* webpackChunkName: "payment-settings" */ '../views/PaymentSettings/PaymentSettings.vue'),
    meta: { requiresFeatureToggle: 'featureTogglePayments' }
  },
  {
    path: '/payment/direct-deposit',
    name: 'Payment Settings - Direct Deposit',
    component: () => import(/* webpackChunkName: "payment-settings-direct-deposit" */ '../views/PaymentSettings/DirectDeposit.vue')
  },
  {
    path: '/documents',
    name: 'Payout Documents',
    component: () => import(/* webpackChunkName: "payout-documents" */ '../views/PayoutDocuments/PayoutDocuments.vue'),
    meta: { requiresFeatureToggle: 'featureToggleRoyalty' }
  },
  {
    path: '/downloads',
    name: 'Downloads',
    component: () => import(/* webpackChunkName: "downloads" */ '../views/Downloads/Downloads.vue'),
    meta: { requiresFeatureToggle: 'featureToggleRoyalty' }
  },
  {
    path: '/intellectualproperty',
    component: () => import(/* webpackChunkName: "intellectual-property-template" */ '../views/IntellectualProperty/Template.vue'),
    meta: { requiresFeatureToggle: 'featureToggleManageSongs' },
    children:[
      {
        path: '',
        redirect: 'songs',
      },
      {
        path: 'songs',
        component: () => import(/* webpackChunkName: "intellectual-property-song-template" */ '../views/IntellectualProperty/SearchSongs/SongTemplate.vue'),
        children:[
          {
            path: '',
            name: 'Search All Songs',
            meta: { requiresFeatureToggle: 'featureToggleManageSongs' },
            component: () => import(/* webpackChunkName: "intellectual-property-song-search" */ '../views/IntellectualProperty/SearchSongs/SearchAllSongs.vue'),
          },
          {
            path: ':ccliSongId',
            name: 'Song Details',
            component: () => import(/* webpackChunkName: "intellectual-property-song-detail" */ '../views/IntellectualProperty/SearchSongs/SongDetail.vue'),
          }
        ]
      },
      {
        path: 'catalogs',
        component: () => import(/* webpackChunkName: "intellectual-property-catalogs-template" */ '../views/IntellectualProperty/Catalogs/CatalogTemplate.vue'),
        children:[
          {
            path: '',
            name: 'Search All Catalogs',
            component: () => import(/* webpackChunkName: "intellectual-property-catalog-search" */ '../views/IntellectualProperty/Catalogs/CatalogSearch.vue'),
          },
          {
            path: ':id',
            name: 'Catalog Details',
            component: () => import(/* webpackChunkName: "intellectual-property-catalogs-detail" */ '../views/IntellectualProperty/Catalogs/CatalogDetail.vue'),
          },
          {
            path: ':catalogid/:ccliSongId',
            name: 'Catalog Song Details',
            component: () => import(/* webpackChunkName: "intellectual-property-catalogs-song-detail" */ '../views/IntellectualProperty/Catalogs/CatalogSongDetail.vue'),
          }
        ]
      },
      {
        path: 'registersongs',
        name: 'Register Songs',
        component: () => import(/* webpackChunkName: "intellectual-property-register-songs" */ '../views/IntellectualProperty/RegisterSongs/RegisterSongs.vue'),
      },
      {
        path: 'registersongs/:id',
        name: 'Register Song Details',
        component: () => import(/* webpackChunkName: "intellectual-property-register-songs-details" */ '../views/IntellectualProperty/RegisterSongs/RegisterSongDetails.vue'),
      }
    ]
  },
  {
    path: '/about/contact',
    redirect: '/contact'
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact/Contact.vue')
  },
  {
    path: '/terms-of-use',
    name: 'Terms Of Use',
    component: () => import(/* webpackChunkName: "contact" */ '../views/TermsOfUse/TermsOfUse.vue')
  },
  {
    path: '/ui',
    name: 'UI',
    component: () => import(/* webpackChunkName: "ui" */ '../views/UI.vue')
  },
  {
    path: '/unavailable',
    name: 'Unavailable',
    component: () => import(/* webpackChunkName: "unavailable" */ '../views/NotAvailable.vue')
  },
  {
    path: '*',
    name: 'Not Found',
    component: () => import(/* webpackChunkName: "notfound" */ '../views/NotFound.vue')
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () => import(/* webpackChunkName: "unauthorized" */ '../views/Unauthorized.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {  
  if (!ProfileService.profile){
    next('/unavailable')

  }else if (!ProfileService.profile.authorized
      && to.name !== 'Contact'
      && to.name !== 'Unavailable'
      && to.name !== 'Not Found'
    ){
    next('/unavailable')

  }else if ( ProfileService.profile && ProfileService.profile.authorized && to.name === 'Unavailable'){
    next('/');

  }else{
    next();
  }
  if (ProfileService.profile) {
    const routeWithFeatureToggle = to.matched.find(route => route.meta.requiresFeatureToggle);
    if (routeWithFeatureToggle) {
      const requiredFeatureToggle = routeWithFeatureToggle.meta.requiresFeatureToggle;
      if (!ProfileService.profile[requiredFeatureToggle as keyof typeof ProfileService.profile]) {
        return next('/unauthorized');
      }
    } else {
      next();
    }
  }
})

export default router
